var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{attrs:{"transparent":true,"type":_vm.type,"wrapper-class":"container ninaspro-navbar","centered":""},scopedSlots:_vm._u([{key:"start",fn:function(){return [_c('b-navbar-item',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],attrs:{"tag":"router-link","to":{ path: '/' },"href":"#projects"}},[_c('p',[_vm._v(_vm._s(_vm.$t('navbar.initiatives')))])]),_c('b-navbar-item',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],attrs:{"tag":"router-link","to":{ path: '/' },"href":"#information"}},[_c('p',[_vm._v(_vm._s(_vm.$t('navbar.about')))])]),_c('b-navbar-item',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],attrs:{"tag":"router-link","to":{ path: '/' },"href":"#events"}},[_c('p',[_vm._v(_vm._s(_vm.$t('navbar.events')))])]),_c('b-navbar-item',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],attrs:{"tag":"router-link","to":{ path: '/' },"href":"#collaborators"}},[_c('p',[_vm._v(_vm._s(_vm.$t('navbar.alliances')))])])]},proxy:true},{key:"end",fn:function(){return [_c('b-navbar-dropdown',{attrs:{"label":`${_vm.$t('navbar.lang')}`},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},[_c('b-navbar-item',{attrs:{"value":"es"},on:{"click":function($event){return _vm.handleChange('es')}}},[_vm._v(" español ")]),_c('b-navbar-item',{attrs:{"value":"en"},on:{"click":function($event){return _vm.handleChange('en')}}},[_vm._v(" english ")])],1),_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('div',{staticClass:"buttons"},[_c('router-link',{attrs:{"to":{ path: '/voluntariado'}}},[_c('b-button',{class:{ 
              'is-info':(_vm.type !== 'is-info'), 
              'is-warning':(_vm.type == 'is-info')
            },attrs:{"id":"involucrate-btn","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t('navbar.involved'))+" ")])],1),_c('a',{attrs:{"href":"https://yodono.cl/proyecto/395/NiasProMujeresProinspirandovocacionesenniasdeChile"}},[_c('b-button',{class:{ 
              'is-warning':(_vm.type !== 'is-warning' && _vm.type !== 'is-info'),
              'is-primary':(_vm.type === 'is-warning' || _vm.type === 'is-info')
            },attrs:{"id":"dona-btn","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t('navbar.donate'))+" ")])],1)],1)])]},proxy:true}])},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ path: '/' }}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"Corporación Niñas Pro"}})])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }